import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'
import classnames from 'classnames'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { withStyles, Typography, Paper, InputLabel } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import actions from '../../actions'
import LabelValue from '../../components/text/LabelValue'
import DisplayObservationLocation from '../../components/maps/DisplayObservationLocation'
import {
  IonButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonContent,
  IonFooter,
  useIonAlert,
  useIonViewWillLeave,
  IonLabel,
} from '@ionic/react'
import { online } from '../../selectors'
import PhotoGallery from '../../components/photos/PhotoGallery'

const styles = (theme) => ({
  reviewSection: {
    marginBottom: '2rem',
    padding: '1rem 1.6rem',

    '& > div': {
      marginBottom: '1rem',
    },
  },
  sectionData: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '1.2rem',
    marginBottom: '2rem',

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& ion-button': {
      width: '50%',
    },
  },
  editLink: {
    color: theme.palette.primary.main,
    display: 'block',
    fontWeight: '600',
    fontSize: '1.1rem',
    marginTop: '0.5rem',
  },
  photoGrid: {
    display: 'grid',
    gridGap: '1rem',
    marginBottom: '1rem',
    gridTemplateColumns: '1fr 1fr',

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },

    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
  },
  reviewLocationMap: {
    margin: '1rem',
  },
  alert: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(3,118,189,0.15)',
    padding: '0.8rem 1rem',
    margin: '1rem 0',
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(249,0,0,0.15)',
    padding: '0.8rem 1rem',
    margin: '1rem 0',
  },
  infoIcon: {
    marginRight: '1rem',
  },
  obsLabel: {
    marginBottom: '1rem',
    color: theme.palette.primary.main,
  },
  colorBandGrid: {
    display: 'grid',
    gridTemplateColumns: '60px 100px 100px',
    alignItems: 'center',
    justifyItems: 'center',
    gap: '10px',
  },
  alignRight: {
    justifySelf: 'end',
  },
  label: {
    marginBottom: '0.4rem',
    color: theme.palette.primary.main,
    fontSize: '1rem',
  },
  value: {
    fontSize: '1.4rem',
  },
})

const ObservationReview = (props) => {
  const {
    classes,
    createObservation,
    data,
    editObservation,
    me,
    projects,
    formTypes,
    enableFormSubmit,
    saveDraftObservation,
    isAuthenticated,
    showFlashMessage,
    clearReviewData,
    formMetadata,
  } = props
  const [submitting, setSubmitting] = useState(false)
  const [presentAlert] = useIonAlert()
  const history = useHistory()
  const location = useLocation()
  const params = useParams()
  const { projectKey, id } = params
  const action = location.pathname.includes('edit') ? 'edit' : 'create'

  useIonViewWillLeave(() => {
    enableFormSubmit()
  })

  const cancel = () => {
    if (formMetadata && formMetadata.fromVerification) {
      history.push(
        `/verification/verification-new/${formMetadata.fromVerification}`,
        { direction: 'forward' }
      )
    } else {
      history.push('/dashboard/projects', { direction: 'forward' })
    }
    clearReviewData()
  }

  const onSubmit = async () => {
    try {
      setSubmitting(true)
      enableFormSubmit()

      if (!isAuthenticated) {
        await saveDraftObservation(projectKey, data)
        showFlashMessage(
          'Observation has been saved on your device. Create an account to share with Nature Mapping.',
          'primary'
        )
        clearReviewData()
        return history.push('/dashboard/me', { direction: 'forward' })
      }

      if (!online) {
        await saveDraftObservation(projectKey, data)
        showFlashMessage(
          'Observation has been saved on your device because there is no network connection.',
          'primary'
        )
        clearReviewData()
        return history.push('/dashboard/me', { direction: 'forward' })
      }

      if (action === 'edit') {
        await editObservation(projectKey, data)
        me()
        if (formMetadata && formMetadata.fromVerification) {
          history.push(
            `/verification/verification-new/${formMetadata.fromVerification}`,
            { direction: 'forward' }
          )
        } else {
          history.push('/dashboard/me', { direction: 'forward' })
        }
        clearReviewData()
        return // edits end here
      }

      await createObservation(projectKey, data)

      // TODO - get rid of the need for this, just store the new observation correctly in reducer on create
      me()
      clearReviewData()
      return history.push('/dashboard/me', { direction: 'forward' })
    } catch (e) {
      setSubmitting(false)
      console.log(e)
      presentAlert({
        header: 'Error',
        message:
          e && e.errors && e.errors[0] && e.errors[0].message
            ? e.errors[0].message
            : 'There was an error submitting your observation. Please try again later.',
        buttons: [
          {
            text: 'OK',
            role: 'cancel',
            handler: () => {
              cancel()
            },
          },
        ],
      })
    }
  }

  if (!data) return null

  const project = projects.find((project) => project.key === projectKey)
  const noLocationProject = ['backyard', 'gameCameras', 'beaver'].includes(
    projectKey
  )
  const noSpeciesProject = ['beaver'].includes(projectKey)
  const gameCamera = formTypes.gameCameras.find(
    (cam) => cam._id === data.gameCamera
  )
  const beaverSite = formTypes.beaverSites.find(
    (site) => site._id === data.beaverSite
  )

  const displayLocationData = data.location && JSON.parse(data.location)

  const nestbox =
    data.nestbox &&
    formTypes.nestboxes.find((box) => box.number === data.nestbox)

  const photos = data.photos ? data.photos.split('|') : []

  const bandResight = data.bandResight ? JSON.parse(data.bandResight) : null

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Review Submission</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {projectKey === 'gameCameras' &&
          !data.doesObservationNeedToBeVerified && (
            <div className={classes.error}>
              <InfoIcon className={classes.infoIcon} color="error" />
              <Typography>
                You have specfied that this observation should not go through
                the normal data verification process. This can not be changed
                later. If this is a mistake, please go back and reselect the
                checkbox on the first step.
              </Typography>
            </div>
          )}
        <Paper className={classes.reviewSection}>
          <Typography gutterBottom variant="h5">
            {project?.name}
          </Typography>
          <div className={classnames(classes.sectionData, classes.overview)}>
            {gameCamera && (
              <>
                <LabelValue label="Camera Name" value={gameCamera.name} />
                <LabelValue
                  label="Needs to be verified?"
                  value={data.doesObservationNeedToBeVerified ? 'Yes' : 'No'}
                />
              </>
            )}
            <LabelValue
              label="Date and Time"
              value={DateTime.fromISO(data.dateTime).toLocaleString(
                DateTime.DATETIME_MED
              )}
            />
            {!noSpeciesProject && (
              <>
                <LabelValue
                  label="Wildlife Type"
                  value={
                    data.speciesType
                      ? formTypes.speciesTypes.find(
                          (type) => type._id === data.speciesType
                        ).name
                      : data.species
                      ? formTypes.species.find(
                          (type) => type.code === data.species
                        ).type.name
                      : 'N/A'
                  }
                />
                <LabelValue
                  label="Species"
                  value={
                    data.species
                      ? formTypes.species.find(
                          (type) => type.code === data.species
                        ).commonName
                      : 'N/A'
                  }
                />
              </>
            )}
            {projectKey === 'wildlifeTour' && (
              <LabelValue
                label="On Wildlife Tour?"
                value={data.onTour ? 'Yes' : 'No'}
              />
            )}
            {projectKey === 'beaver' && (
              <>
                <LabelValue label="Site Id" value={beaverSite.siteId} />
                <LabelValue label="Tributary" value={beaverSite.name} />
                <LabelValue
                  label="Current Activities"
                  value={
                    data.currentActivities &&
                    data.currentActivities
                      .map(
                        (activityId) =>
                          formTypes.beaverActivities.find(
                            (b) => b._id === activityId
                          ).description
                      )
                      .join(', ')
                  }
                />
                <LabelValue
                  label="Recent Activities"
                  value={
                    data.recentActivities &&
                    data.recentActivities
                      .map(
                        (activityId) =>
                          formTypes.beaverActivities.find(
                            (b) => b._id === activityId
                          ).description
                      )
                      .join(', ')
                  }
                />
                <LabelValue
                  label="Past Activities"
                  value={
                    data.pastActivities &&
                    data.pastActivities
                      .map(
                        (activityId) =>
                          formTypes.beaverActivities.find(
                            (b) => b._id === activityId
                          ).description
                      )
                      .join(', ')
                  }
                />
                <LabelValue
                  label="No Activity"
                  value={data.noActivity ? 'Checked' : 'Not checked'}
                />
                <div>
                  <LabelValue label="Notes" value={data.comment} />
                </div>
              </>
            )}
            {bandResight && (
              <>
                <div
                  className={classnames(
                    classes.sectionData,
                    classes.bandResightData
                  )}
                >
                  <LabelValue
                    label="Is bird banded?"
                    value={bandResight.isBanded}
                  />
                  <LabelValue
                    label="Banded bird sex"
                    value={
                      bandResight.sex
                        ? formTypes.sexes.find(
                            (type) => type._id === bandResight.sex
                          ).description
                        : '-'
                    }
                  />
                  <div>
                    <IonLabel className={classes.label}>
                      {'Color band combination'}
                    </IonLabel>
                    <div className={classes.colorBandGrid}>
                      <div></div>
                      <IonLabel className={classes.label}>Left</IonLabel>
                      <IonLabel className={classes.label}>Right</IonLabel>
                      <IonLabel
                        className={classnames(
                          classes.alignRight,
                          classes.label
                        )}
                      >
                        Top
                      </IonLabel>
                      <IonLabel
                        className={classes.value}
                        id="bandResight.colorBandCombination.topLeft"
                      >
                        {bandResight.colorBandCombination.topLeft
                          ? bandResight.colorBandCombination.topLeft
                          : '-'}
                      </IonLabel>
                      <IonLabel
                        className={classes.value}
                        id="bandResight.colorBandCombination.topRight"
                      >
                        {bandResight.colorBandCombination.topRight
                          ? bandResight.colorBandCombination.topRight
                          : '-'}
                      </IonLabel>
                      <IonLabel
                        className={classnames(
                          classes.alignRight,
                          classes.label
                        )}
                      >
                        Bottom
                      </IonLabel>
                      <IonLabel
                        className={classes.value}
                        id="bandResight.colorBandCombination.bottomLeft"
                      >
                        {bandResight.colorBandCombination.bottomLeft
                          ? bandResight.colorBandCombination.bottomLeft
                          : '-'}
                      </IonLabel>
                      <IonLabel
                        className={classes.value}
                        id="bandResight.colorBandCombination.bottomRight"
                      >
                        {bandResight.colorBandCombination.bottomRight
                          ? bandResight.colorBandCombination.bottomRight
                          : '-'}
                      </IonLabel>
                    </div>
                  </div>
                </div>
                <div
                  className={classnames(
                    classes.sectionData,
                    classes.bandResightData
                  )}
                >
                  <LabelValue
                    label="Is second bird banded?"
                    value={bandResight.isBanded2 ? bandResight.isBanded2 : '-'}
                  />
                  <LabelValue
                    label="Second banded bird sex"
                    value={
                      bandResight.sex2
                        ? formTypes.sexes.find(
                            (type) => type._id === bandResight.sex2
                          ).description
                        : '-'
                    }
                  />
                  <div>
                    <IonLabel className={classes.label}>
                      {'Color band combination'}
                    </IonLabel>
                    <div className={classes.colorBandGrid}>
                      <div></div>
                      <IonLabel className={classes.label}>Left</IonLabel>
                      <IonLabel className={classes.label}>Right</IonLabel>
                      <IonLabel
                        className={classnames(
                          classes.alignRight,
                          classes.label
                        )}
                      >
                        Top
                      </IonLabel>
                      <IonLabel
                        className={classes.value}
                        id="bandResight.colorBandCombination2.topLeft"
                      >
                        {bandResight.colorBandCombination2.topLeft
                          ? bandResight.colorBandCombination2.topLeft
                          : '-'}
                      </IonLabel>
                      <IonLabel
                        className={classes.value}
                        id="bandResight.colorBandCombination2.topRight"
                      >
                        {bandResight.colorBandCombination2.topRight
                          ? bandResight.colorBandCombination2.topRight
                          : '-'}
                      </IonLabel>
                      <IonLabel
                        className={classnames(
                          classes.alignRight,
                          classes.label
                        )}
                      >
                        Bottom
                      </IonLabel>
                      <IonLabel
                        className={classes.value}
                        id="bandResight.colorBandCombination2.bottomLeft"
                      >
                        {bandResight.colorBandCombination2.bottomLeft
                          ? bandResight.colorBandCombination2.bottomLeft
                          : '-'}
                      </IonLabel>
                      <IonLabel
                        className={classes.value}
                        id="bandResight.colorBandCombination2.bottomRight"
                      >
                        {bandResight.colorBandCombination2.bottomRight
                          ? bandResight.colorBandCombination2.bottomRight
                          : '-'}
                      </IonLabel>
                    </div>
                  </div>
                </div>
                <LabelValue
                  label="Banded bird notes"
                  value={bandResight.comments ? bandResight.comments : '-'}
                />
              </>
            )}
          </div>
          <Typography
            className={classes.editLink}
            component={Link}
            onClick={enableFormSubmit}
            to={
              action === 'edit'
                ? `/observation/edit/${projectKey}/${id}/0`
                : `/observation/create/${projectKey}/0`
            }
          >
            Edit
          </Typography>
        </Paper>
        {!noSpeciesProject && (
          <Paper className={classes.reviewSection}>
            <Typography gutterBottom variant="h5">
              Observations
            </Typography>
            {data.observations && projectKey === 'nestboxProject' ? (
              <div
                className={classnames(classes.sectionData, classes.nestboxData)}
              >
                <LabelValue label="Nestbox" value={nestbox.number} />
                <LabelValue
                  label="Nest Material?"
                  value={data.nestMaterial ? 'Yes' : 'No'}
                />
                <LabelValue
                  label="Eggs"
                  value={
                    data.observations.eggs === null ||
                    data.observations.eggs === undefined
                      ? '-'
                      : data.observations.eggs
                  }
                />
                <LabelValue
                  label="Nestlings"
                  value={
                    data.observations.nestlings === null ||
                    data.observations.nestlings === undefined
                      ? '-'
                      : data.observations.nestlings
                  }
                />
                <LabelValue
                  label="Fledglings"
                  value={
                    data.observations.fledglings === null ||
                    data.observations.fledglings === undefined
                      ? '-'
                      : data.observations.fledglings
                  }
                />
                <LabelValue
                  label="Unhatched Eggs"
                  value={
                    data.observations.unhatchedEggs === null ||
                    data.observations.unhatchedEggs === undefined
                      ? '-'
                      : data.observations.unhatchedEggs
                  }
                />
                <LabelValue
                  label="Dead Nestings"
                  value={
                    data.observations.deadNestlings === null ||
                    data.observations.deadNestlings === undefined
                      ? '-'
                      : data.observations.deadNestlings
                  }
                />
              </div>
            ) : data.observations && projectKey === 'wildlifeTour' ? (
              <>
                <LabelValue
                  label="Quantity"
                  value={data.observations.quantity}
                />
                <LabelValue
                  label="Activity"
                  value={
                    formTypes.activities.find(
                      (type) => type._id === data.observations.activity
                    ).description
                  }
                />
              </>
            ) : (
              data.observations &&
              data.observations.length &&
              data.observations.map((observation, index) => {
                return (
                  <Fragment key={index}>
                    <InputLabel className={classes.obsLabel}>{`Observation ${
                      index + 1
                    }`}</InputLabel>
                    <div
                      className={classnames(
                        classes.sectionData,
                        classes.observationData
                      )}
                    >
                      <LabelValue
                        label="Sex"
                        value={
                          formTypes.sexes.find(
                            (type) => type._id === observation.sex
                          ).description
                        }
                      />
                      <LabelValue
                        label="Age"
                        value={
                          formTypes.ages.find(
                            (type) => type._id === observation.age
                          ).description
                        }
                      />
                      <LabelValue
                        label="Quantity"
                        value={observation.quantity}
                      />
                      <LabelValue
                        label="Activity"
                        value={
                          formTypes.activities.find(
                            (type) => type._id === observation.activity
                          ).description
                        }
                      />
                      <LabelValue
                        label="Mortality"
                        value={
                          formTypes.mortality.find(
                            (type) => type._id === observation.mortality
                          ).description
                        }
                      />
                    </div>
                  </Fragment>
                )
              })
            )}
            <div className={classnames(classes.notes)}>
              <LabelValue
                label="Notes"
                value={data.comment ? data.comment : 'None'}
              />
            </div>
            <Typography
              className={classes.editLink}
              component={Link}
              onClick={enableFormSubmit}
              to={
                action === 'edit'
                  ? `/observation/edit/${projectKey}/${id}/1`
                  : `/observation/create/${projectKey}/1`
              }
            >
              Edit
            </Typography>
          </Paper>
        )}
        <Paper className={classes.reviewSection}>
          <Typography gutterBottom variant="h5">
            Location
          </Typography>
          <DisplayObservationLocation
            location={
              nestbox ||
              gameCamera ||
              (beaverSite && beaverSite.name !== 'Other' && beaverSite) ||
              displayLocationData
            }
          />
          {!noLocationProject && (
            <Typography
              className={classes.editLink}
              component={Link}
              onClick={enableFormSubmit}
              to={
                action === 'edit'
                  ? `/observation/edit/${projectKey}/${id}/2`
                  : `/observation/create/${projectKey}/2`
              }
            >
              Edit
            </Typography>
          )}
        </Paper>
        <Paper className={classes.reviewSection}>
          <Typography gutterBottom variant="h5">
            Photos
          </Typography>
          {photos && photos.length > 0 && photos[0] !== '' ? (
            <div className={classes.photoGrid}>
              <PhotoGallery photos={photos} />
            </div>
          ) : (
            <Typography gutterBottom>
              No photos were added to this observation
            </Typography>
          )}
          <Typography
            className={classes.editLink}
            component={Link}
            onClick={enableFormSubmit}
            to={
              action === 'edit'
                ? `/observation/edit/${projectKey}/${id}/${
                    noLocationProject ? (projectKey === 'beaver' ? 1 : 2) : 3
                  }`
                : `/observation/create/${projectKey}/${
                    noLocationProject ? (projectKey === 'beaver' ? 1 : 2) : 3
                  }`
            }
          >
            Edit
          </Typography>
        </Paper>
        {projectKey === 'backyard' && (
          <div className={classes.alert}>
            <InfoIcon className={classes.infoIcon} color="secondary" />
            <Typography>
              Remember that for Project Backyard, the data protocol requires
              entering the top # of individuals by species per week.
            </Typography>
          </div>
        )}
        {projectKey === 'gameCameras' &&
          !data.doesObservationNeedToBeVerified && (
            <div className={classes.error}>
              <InfoIcon className={classes.infoIcon} color="error" />
              <Typography>
                You have specfied that this observation should not go through
                the normal data verification process. This can not be changed
                later. If this is a mistake, please go back and reselect the
                checkbox on the first step.
              </Typography>
            </div>
          )}
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <div className={classes.buttons}>
            <IonButton
              color="secondary"
              disabled={submitting}
              fullWidth
              onClick={() =>
                presentAlert({
                  header: 'Are you sure?',
                  message:
                    action === 'edit'
                      ? 'Any edits will be lost, do you want to proceed?'
                      : 'Data entered will be lost, do you want to proceed?',
                  buttons: [
                    {
                      text: 'Cancel',
                      role: 'cancel',
                      handler: () => {},
                    },
                    {
                      text: 'Yes',
                      role: 'confirm',
                      handler: () => {
                        cancel()
                      },
                    },
                  ],
                })
              }
            >
              Cancel
            </IonButton>
            <IonButton disabled={submitting} fullWidth onClick={onSubmit}>
              Submit
            </IonButton>
          </div>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  )
}

function mapStateToProps({ form, projects, auth }) {
  return {
    data: form.data,
    formTypes: form.types,
    formMetadata: form.metadata,
    isAuthenticated: auth.isAuthenticated,
    projects,
  }
}

const mapDispatchToProps = {
  createObservation: actions.createObservation,
  clearReviewData: actions.clearReviewData,
  editObservation: actions.editObservation,
  enableFormSubmit: actions.enableFormSubmit,
  me: actions.me,
  showFlashMessage: actions.showFlashMessage,
  saveDraftObservation: actions.saveDraftObservation,
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ObservationReview)
)
