/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  useBlockLayout,
  useRowSelect,
} from 'react-table'
import { DateTime } from 'luxon'
import { Chip, Typography } from '@material-ui/core'
import ScrollContainer from 'react-indiana-drag-scroll'
import 'photoswipe/dist/photoswipe.css'
import { IonButton, useIonViewWillEnter } from '@ionic/react'
import {
  batchReject,
  batchVerify,
  setCurrentVerificationIndex,
} from '../../actions/observations.actions'
import TablePagination from './TablePagination'
import PhotoGallery from '../photos/PhotoGallery'

const propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    })
  ).isRequired,
  fetchData: PropTypes.func.isRequired,
}

const styles = (theme) => ({
  tableWrapper: {
    position: 'relative',
    minWidth: 350,
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  table: {
    display: 'block',
    width: '100%',
    marginBottom: '0.5rem',
    minHeight: '8rem',

    '& tbody tr': {
      borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
      minHeight: '1.8rem',

      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.textColor,
        cursor: 'pointer',
      },

      '& td': {
        alignSelf: 'center',
        padding: '0.5rem 0.6rem',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },

    '& thead': {
      boxShadow: 'none',
      borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
      fontWeight: 600,

      '& th': {
        padding: '0.6rem 0.3rem',

        '& input': {
          borderRadius: 4,
          border: `1px solid ${theme.palette.primary.borderColor}`,
          padding: '0 0.3rem',
          width: '100%',
        },
      },
    },
  },
  tableButton: {
    borderRadius: 0,
    boxShadow: 'none',
    width: '100%',
  },
  rowHover: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.textColor,
  },
  loading: {
    textAlign: 'center',
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.5)',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,

    '& p': {
      position: 'absolute',
      backgroundColor: 'rgba(255,255,255,0.9)',
      width: '100%',
      top: 0,
      padding: '0.5rem',
    },
  },
  sortIcon: {
    display: 'inline-block',
    marginLeft: '1rem',
  },
  tableActions: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: '0.5rem 0.5rem 0.5rem',
  },
  selectionCell: {
    padding: '0.1rem !important',
    textOverflow: 'initial !important',
  },
  tableMapList: {
    maxHeight: '90vh',
    padding: 0,
    margin: 0,
    overflowX: 'hidden',

    '& li': {
      width: '100%',
    },
  },
  observationItem: {
    borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
    boxSizing: 'border-box',
    cursor: 'pointer',
    padding: '0.5rem 1rem',
    position: 'relative',
    minHeight: '60px',
    width: '100%',
  },
  topRightCornerOfObservationItem: {
    position: 'absolute',
    top: '0.7rem',
    right: '1rem',
    textAlign: 'right',

    '& > *': {
      display: 'block',
    },
  },
  filterList: {
    borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '0.4rem 10px',
    padding: '0.8rem',

    '& *': {
      display: 'flex',
      width: '100% !important',
    },
  },
  tableInfoText: {
    display: 'block',
    padding: '1rem',
  },
  headerRow: {
    alignItems: 'flex-end',
  },
  filterHeight: {
    minHeight: '22px',
  },
  filtersAlert: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    backgroundColor: 'rgba(3, 118, 189, 0.15)',
    padding: '0.5rem',
    borderRadius: 4,
  },
  observationImage: {
    cursor: 'pointer',
    maxWidth: '300px',
  },
  imagesRow: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 20,
    padding: 20,
    paddingBottom: 80,

    '&:hover': {
      backgroundColor: 'initial !important',
      cursor: 'initial !important',
    },
  },
})

function DefaultColumnFilter({ column: { filterValue, setFilter, Header } }) {
  return (
    <input
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
      placeholder={`Filter by ${Header.toLowerCase()}...`}
      value={filterValue || ''}
    />
  )
}

const renderDataTable = ({
  classes,
  TableInstance,
  hoveredObservation,
  setHoveredObservation,
  onCellClick,
  total,
  loading,
  tableKey,
  selectedFlatRows,
  showPhotos,
  history,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    TableInstance

  return (
    <Fragment>
      <div className={classes.tableWrapper}>
        <ScrollContainer
          className={`${tableKey}-container`}
          hideScrollbars={false}
          ignoreElements={'thead'}
          style={{ width: '100%' }}
        >
          <table className={classes.table} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr
                  className={classes.headerRow}
                  key={`hg${index}`}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column, index) => (
                    <th key={`hc${index}`} {...column.getHeaderProps()}>
                      <div>
                        <span {...column.getSortByToggleProps()}>
                          {column.render('Header')}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <span
                                aria-label="descending sort icon"
                                className={classes.sortIcon}
                                role="img"
                              >
                                🔽
                              </span>
                            ) : (
                              <span
                                aria-label="ascending sort icon"
                                className={classes.sortIcon}
                                role="img"
                              >
                                🔼
                              </span>
                            )
                          ) : (
                            ''
                          )}
                        </span>
                      </div>
                      <div
                        className={
                          column.id !== 'selection' ? classes.filterHeight : ''
                        }
                      >
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <>
                    <tr
                      className={
                        hoveredObservation === row.original._id ||
                        selectedFlatRows.some(
                          (selectedRow) =>
                            selectedRow.original._id === row.original._id
                        )
                          ? classes.rowHover
                          : ''
                      }
                      key={`row-${row.original._id}`}
                      onMouseLeave={() =>
                        setHoveredObservation && setHoveredObservation(null)
                      }
                      onMouseOver={() =>
                        setHoveredObservation &&
                        setHoveredObservation(row.original._id)
                      }
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell, index) => {
                        return (
                          <td
                            className={
                              cell.column.id === 'selection'
                                ? classes.selectionCell
                                : ''
                            }
                            key={`cell-${row.original._id}-${index}`}
                            onClick={(e) => onCellClick(e, cell, tableKey)}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                    {showPhotos && (
                      <tr className={classes.imagesRow}>
                        <PhotoGallery photos={row.original.photos} />
                      </tr>
                    )}
                  </>
                )
              })}
            </tbody>
          </table>
        </ScrollContainer>
      </div>
      <TablePagination loading={loading} total={total} {...TableInstance} />
    </Fragment>
  )
}

const renderDataWithMapList = ({
  classes,
  TableInstance,
  hoveredObservation,
  setHoveredObservation,
  onCellClick,
  total,
  loading,
  selectedFlatRows,
  tableKey,
}) => {
  const { getTableProps, headerGroups, prepareRow, page } = TableInstance

  return (
    <Fragment>
      <div className={classes.tableWrapper}>
        <div className={classes.filterList}>
          {headerGroups.map((headerGroup) =>
            headerGroup.headers
              .filter((column) => column.canFilter)
              .map((column) => {
                return (
                  <div {...column.getHeaderProps()}>
                    <div>
                      <span {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <span
                              aria-label="descending sort icon"
                              className={classes.sortIcon}
                              role="img"
                            >
                              🔽
                            </span>
                          ) : (
                            <span
                              aria-label="ascending sort icon"
                              className={classes.sortIcon}
                              role="img"
                            >
                              🔼
                            </span>
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                    <div>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </div>
                )
              })
          )}
        </div>
        <ul className={classes.tableMapList} {...getTableProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <li
                className={
                  hoveredObservation === row.original._id ||
                  selectedFlatRows.some(
                    (selectedRow) =>
                      selectedRow.original._id === row.original._id
                  )
                    ? classes.rowHover
                    : ''
                }
                key={`row${i}`}
                onClick={(e) => onCellClick(e, row.cells[1], tableKey)}
                onMouseLeave={() =>
                  setHoveredObservation && setHoveredObservation(null)
                }
                onMouseOver={() =>
                  setHoveredObservation &&
                  setHoveredObservation(row.original._id)
                }
              >
                <div className={classes.observationItem}>
                  <div className={classes.topRightCornerOfObservationItem}>
                    <Typography variant="caption">
                      {DateTime.fromISO(
                        row.original.obsDateTime
                      ).toLocaleString(DateTime.DATE_SHORT)}
                    </Typography>
                    <Typography variant="caption">
                      {DateTime.fromISO(
                        row.original.obsDateTime
                      ).toLocaleString(DateTime.TIME_24_SIMPLE)}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="h6">{`${row.original.species} ${
                      row.original.quantity ? `-  ${row.original.quantity}` : ''
                    }`}</Typography>
                    <Typography variant="caption">
                      {row.original.speciesType}
                    </Typography>
                  </div>
                  <div>
                    <Typography>{row.original.project}</Typography>
                    {/* <Typography>{row.original.creator}</Typography> */}
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
      <TablePagination loading={loading} total={total} {...TableInstance} />
    </Fragment>
  )
}

const NatureMappingTable = ({
  classes,
  columns,
  data,
  fetchData,
  initialState,
  loading,
  pageCount: controlledPageCount,
  onCellClick,
  total,
  tableKey,
  tableView,
  batchReject,
  batchVerify,
  verifyActions,
  showPhotos = false,
}) => {
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const history = useHistory()

  const TableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState,
      manualPagination: true,
      manualFilters: true,
      manualSorting: true,
      pageCount: controlledPageCount,
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    useBlockLayout
  )

  const {
    selectedFlatRows,
    pageOptions,
    gotoPage,
    state: { pageIndex, pageSize, filters, sortBy },
  } = TableInstance

  useEffect(() => {
    fetchData({ pageIndex, pageSize, filters, sortBy })
  }, [
    fetchData,
    pageIndex,
    pageSize,
    JSON.stringify(filters),
    JSON.stringify(sortBy),
  ])

  useIonViewWillEnter(() => {
    fetchData({ pageIndex, pageSize, filters, sortBy })
  }, [
    fetchData,
    pageIndex,
    pageSize,
    JSON.stringify(filters),
    JSON.stringify(sortBy),
  ])

  if (pageIndex > pageOptions.length) {
    gotoPage(0)
    return null
  }

  function defaultOnCellClick(event, cell, tableKey) {
    if (
      cell.row &&
      cell.row.original &&
      cell.column &&
      cell.column.id !== 'selection'
    ) {
      return history.push(`/observation/${cell.row.original._id}`)
    }
  }

  return (
    <div>
      {verifyActions && (
        <div className={classes.tableActions}>
          <IonButton
            disabled={selectedFlatRows.length < 1}
            onClick={() =>
              batchVerify(selectedFlatRows.map((d) => d.original._id)).then(
                fetchData({ pageIndex, pageSize, filters, sortBy })
              )
            }
          >
            Verify selected
          </IonButton>
          <IonButton
            disabled={selectedFlatRows.length < 1}
            onClick={() =>
              batchReject(selectedFlatRows.map((d) => d.original._id)).then(
                fetchData({ pageIndex, pageSize, filters, sortBy })
              )
            }
          >
            Reject selected
          </IonButton>
        </div>
      )}
      {filters.length > 0 && (
        <div className={classes.filtersAlert}>
          <Typography variant="caption">
            {filters.length} filters applied:
          </Typography>
          {filters.map((filter) => (
            <Chip
              key={filter.id}
              label={`${filter.id}: ${filter.value}`}
              onDelete={() => {
                TableInstance.setFilter(filter.id, undefined)
              }}
            />
          ))}
        </div>
      )}
      {!tableView
        ? renderDataWithMapList({
            classes,
            TableInstance,
            onCellClick: onCellClick || defaultOnCellClick,
            loading,
            total,
            tableKey,
            selectedFlatRows,
            history,
          })
        : renderDataTable({
            classes,
            TableInstance,
            onCellClick: onCellClick || defaultOnCellClick,
            loading,
            total,
            tableKey,
            selectedFlatRows,
            showPhotos,
            history,
          })}
    </div>
  )
}

const mapDispatchToProps = {
  batchReject,
  batchVerify,
  setCurrentVerificationIndex,
}

NatureMappingTable.propTypes = propTypes

export default withStyles(styles)(
  connect(null, mapDispatchToProps)(NatureMappingTable)
)