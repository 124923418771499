/* eslint-disable prefer-destructuring */
/* eslint-disable no-undef */
import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import set from 'lodash.set'
import actions from '../../../actions'
import {
  IonInput,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
} from '@ionic/react'
import { safeWrap } from '../../../helpers/utils'

const styles = (theme) => ({
  root: {
    marginTop: '0.4rem',
  },
  fieldSpacing: {
    marginBottom: '2rem',
  },
  colorBandGrid: {
    display: 'grid',
    gridTemplateColumns: '60px 100px 100px',
    alignItems: 'center',
    justifyItems: 'center',
    gap: '10px',
  },
  alignRight: {
    justifySelf: 'end',
  },
})

const BandResightWidget = ({
  classes,
  onChange,
  value,
  sexes,
}) => {
  const {
    isBanded,
    sex,
    colorBandCombination = {},
    isBanded2,
    sex2,
    colorBandCombination2 = {},
    comments,
  } = value ? JSON.parse(value) : {}

  const handleInputChange = (fieldName, newValue) => {
    let updateValue = {
      isBanded,
      sex,
      colorBandCombination,
      isBanded2,
      sex2,
      colorBandCombination2,
      comments,
    }

    set(updateValue, fieldName, newValue)

    // form schema expects a string
    onChange(JSON.stringify(updateValue))
  }

  return (
    <div className={classes.root}>
      <div className={classes.fieldSpacing}>
        <IonRadioGroup
          id="isBanded"
          onIonBlur={(e) => handleInputChange('isBanded', e.target.value)}
          onIonChange={(e) => handleInputChange('isBanded', e.target.value)}
          required={true}
          value={isBanded}
        >
          <IonItem key={'yes'}>
            <IonLabel>{'Yes'}</IonLabel>
            <IonRadio slot="start" value={'Yes'} />
          </IonItem>
          <IonItem key={'no'}>
            <IonLabel>{'No'}</IonLabel>
            <IonRadio slot="start" value={'No'} />
          </IonItem>
          <IonItem key={'unknown'}>
            <IonLabel>{'Unknown'}</IonLabel>
            <IonRadio slot="start" value={'Unknown'} />
          </IonItem>
        </IonRadioGroup>
      </div>
      {isBanded === 'Yes' && (
        <>
          <div className={classes.fieldSpacing}>
            <IonLabel className={'control-label'}>{'Sex'}</IonLabel>
            <IonRadioGroup
              id="sex"
              onIonBlur={(e) => handleInputChange('sex', e.target.value)}
              onIonChange={(e) =>
                handleInputChange('sex', e.target.value)
              }
              value={sex}
            >
              {sexes.map((sex) => (
                <IonItem key={sex._id}>
                  <IonLabel>{sex.description}</IonLabel>
                  <IonRadio slot="start" value={sex._id} />
                </IonItem>
              ))}
            </IonRadioGroup>
          </div>
          <div className={classes.fieldSpacing}>
            <IonLabel className={'control-label'}>
              {'Color band combination'}
            </IonLabel>
            <div className={classes.colorBandGrid}>
              <div></div>
              <IonLabel>Left</IonLabel>
              <IonLabel>Right</IonLabel>
              <IonLabel className={classes.alignRight}>Top</IonLabel>
              <IonInput
                id="colorBandCombination.topLeft"
                onIonChange={(e) =>
                  handleInputChange(
                    'colorBandCombination.topLeft',
                    e.target.value
                  )
                }
                value={colorBandCombination.topLeft}
              />
              <IonInput
                id="colorBandCombination.topRight"
                onIonChange={(e) =>
                  handleInputChange(
                    'colorBandCombination.topRight',
                    e.target.value
                  )
                }
                value={colorBandCombination.topRight}
              />
              <IonLabel className={classes.alignRight}>Bottom</IonLabel>
              <IonInput
                id="colorBandCombination.bottomLeft"
                onIonChange={(e) =>
                  handleInputChange(
                    'colorBandCombination.bottomLeft',
                    e.target.value
                  )
                }
                value={colorBandCombination.bottomLeft}
              />
              <IonInput
                id="colorBandCombination.bottomRight"
                onIonChange={(e) =>
                  handleInputChange(
                    'colorBandCombination.bottomRight',
                    e.target.value
                  )
                }
                value={colorBandCombination.bottomRight}
              />
            </div>
          </div>
          <div className={classes.fieldSpacing}>
            <IonLabel className={'control-label'}>
              {'Was there another banded bluebird?'}
            </IonLabel>
            <IonRadioGroup
              id="isBanded2"
              onIonBlur={(e) => handleInputChange('isBanded2', e.target.value)}
              onIonChange={(e) =>
                handleInputChange('isBanded2', e.target.value)
              }
              required={true}
              value={isBanded2}
            >
              <IonItem key={'yes'}>
                <IonLabel>{'Yes'}</IonLabel>
                <IonRadio slot="start" value={'Yes'} />
              </IonItem>
              <IonItem key={'no'}>
                <IonLabel>{'No'}</IonLabel>
                <IonRadio slot="start" value={'No'} />
              </IonItem>
              <IonItem key={'unknown'}>
                <IonLabel>{'Unknown'}</IonLabel>
                <IonRadio slot="start" value={'Unknown'} />
              </IonItem>
            </IonRadioGroup>
          </div>
        </>
      )}
      {isBanded2 === 'Yes' && (
        <>
          <div className={classes.fieldSpacing}>
            <IonLabel className={'control-label'}>
              {'Sex of second bluebird'}
            </IonLabel>
            <IonRadioGroup
              id="sex2"
              onIonBlur={(e) => handleInputChange('sex2', e.target.value)}
              onIonChange={(e) =>
                handleInputChange('sex2', e.target.value)
              }
              value={sex2}
            >
              {sexes.map((sex) => (
                <IonItem key={sex._id}>
                  <IonLabel>{sex.description}</IonLabel>
                  <IonRadio slot="start" value={sex._id} />
                </IonItem>
              ))}
            </IonRadioGroup>
          </div>
          <div className={classes.fieldSpacing}>
            <IonLabel className={'control-label'}>
              {'Color band combination of second bluebird'}
            </IonLabel>
            <div className={classes.colorBandGrid}>
              <div></div>
              <IonLabel>Left</IonLabel>
              <IonLabel>Right</IonLabel>
              <IonLabel className={classes.alignRight}>Top</IonLabel>
              <IonInput
                id="colorBandCombination2.topLeft"
                onIonChange={(e) =>
                  handleInputChange(
                    'colorBandCombination2.topLeft',
                    e.target.value
                  )
                }
                value={colorBandCombination2.topLeft}
              />
              <IonInput
                id="colorBandCombination2.topRight"
                onIonChange={(e) =>
                  handleInputChange(
                    'colorBandCombination2.topRight',
                    e.target.value
                  )
                }
                value={colorBandCombination2.topRight}
              />
              <IonLabel className={classes.alignRight}>Bottom</IonLabel>
              <IonInput
                id="colorBandCombination2.bottomLeft"
                onIonChange={(e) =>
                  handleInputChange(
                    'colorBandCombination2.bottomLeft',
                    e.target.value
                  )
                }
                value={colorBandCombination2.bottomLeft}
              />
              <IonInput
                id="colorBandCombination2.bottomRight"
                onIonChange={(e) =>
                  handleInputChange(
                    'colorBandCombination2.bottomRight',
                    e.target.value
                  )
                }
                value={colorBandCombination2.bottomRight}
              />
            </div>
          </div>
        </>
      )}
      {isBanded === 'Yes' && (
        <div className={classes.fieldSpacing}>
          <IonLabel className={'control-label'}>
            {'Comments about the banded birds'}
          </IonLabel>

          <textarea
            className="form-control"
            onChange={(e) =>
              handleInputChange(
                'comments',
                e.target.value
              )
            }
            placeholder="Any notes about the banded birds?"
            resize={'false'}
            rows={4}
            value={comments}
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({ form }) => {
  return {
    sexes: form && form.types && form.types.sexes,
  }
}

export default safeWrap(
  connect(
    mapStateToProps,
    null
  )(withStyles(styles)(BandResightWidget))
)
