import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core'
import LabelValue from '../text/LabelValue'

const styles = (theme) => ({
  root: {},
})

const MountainBluebirdDetails = (props) => {
  const { observation } = props

  return (
    <Fragment>
      <LabelValue
        label="Nestbox #"
        value={observation.nestbox && observation.nestbox.number}
      />
      <LabelValue
        label="Nest Material"
        value={observation.nestMaterial ? 'Yes' : 'No'}
      />
      <LabelValue
        label="Eggs"
        value={
          observation.eggs === undefined || observation.eggs === null
            ? '-'
            : observation.eggs
        }
      />
      <LabelValue
        label="Unhatched Eggs"
        value={
          observation.unhatchedEggs === undefined ||
          observation.unhatchedEggs === null
            ? '-'
            : observation.unhatchedEggs
        }
      />

      <LabelValue
        label="Nestlings"
        value={
          observation.nestlings === undefined ||
          observation.nestlings === null
            ? '-'
            : observation.nestlings
        }
      />

      <LabelValue
        label="Fledglings"
        value={
          observation.fledglings === undefined ||
          observation.fledglings === null
            ? '-'
            : observation.fledglings
        }
      />

      <LabelValue
        label="Dead Nestlings"
        value={
          observation.deadNestlings === undefined ||
          observation.deadNestlings === null
            ? '-'
            : observation.deadNestlings
        }
      />
    </Fragment>
  )
}

export default withStyles(styles)(MountainBluebirdDetails)
