import { DateTime } from 'luxon'

// species to include in this project and also sort order
const speciesList = ['N/A', '614', '615','768', '721', '735', '688.2', 'U']

const nestboxSort = (el1, el2) => {
  return parseInt(el1.number) - parseInt(el2.number)
}

const createSchema = (formTypes) => ({
  steps: [
    {
      type: 'object',
      required: ['dateTime', 'species'],
      properties: {
        nestbox: {
          title: 'What Nestbox is this?',
          type: 'string',
          enum: formTypes
            ? formTypes.nestboxes.sort(nestboxSort).map((box) => box.number)
            : [],
          enumNames: formTypes
            ? formTypes.nestboxes.sort(nestboxSort).map((box) => box.number)
            : [],
          default: null,
        },
        nestMaterial: {
          title: 'Nest Material?',
          type: 'boolean',
          default: false,
        },
        species: {
          type: 'string',
          title: 'What species was it?',
          enum: formTypes
            ? formTypes.speciesByType[
                formTypes.speciesTypes.find((type) => type.name === 'Birds')._id
              ]
                .filter((species) => speciesList.includes(species.code))
                .concat(formTypes.species.find((item) => item.code === 'N/A'))
                .concat(formTypes.species.find((item) => item.code === 'U'))
                .sort((a, b) =>
                  speciesList.indexOf(a.code) > speciesList.indexOf(b.code)
                    ? 1
                    : -1
                )
                .map((species) => species.code)
            : [],
          enumNames: formTypes
            ? formTypes.speciesByType[
                formTypes.speciesTypes.find((type) => type.name === 'Birds')._id
              ]
                .filter((species) => speciesList.includes(species.code))
                .concat(formTypes.species.find((item) => item.code === 'N/A'))
                .concat(formTypes.species.find((item) => item.code === 'U'))
                .sort((a, b) =>
                  speciesList.indexOf(a.code) > speciesList.indexOf(b.code)
                    ? 1
                    : -1
                )
                .map((species) => species.commonName)
            : [],
        },
        bandResight: {},
        dateTime: {
          title: 'When did you make this observation?',
          type: 'string',
          format: 'date-time',
          default: DateTime.local().toISO(),
        },
        comment: {
          type: 'string',
          title: 'Notes',
        },
      },
      dependencies: {
        species: {
          oneOf: [
            {
              properties: {
                species: {
                  enum: ['768', 768],
                },
                bandResight: {
                  title: 'Was the bluebird banded?',
                  type: 'string',
                }
              },
              required: ['bandResight'],
            },
          ],
        },
      },
    },
    {
      definitions: {
        observation: {
          type: 'object',
          properties: {
            eggs: {
              title: 'Number of eggs',
              type: ['number', 'null'],
              default: null,
            },
            nestlings: {
              title: 'Number of nestlings',
              type: ['number', 'null'],
              default: null,
            },
            fledglings: {
              title: 'Number of fledglings',
              type: ['number', 'null'],
              default: null,
            },
            unhatchedEggs: {
              type: ['number', 'null'],
              title: 'Number of unhatched eggs',
              default: null,
            },
            deadNestlings: {
              type: ['number', 'null'],
              title: 'Number of dead nestlings',
              default: null,
            },
          },
        },
      },
      type: 'object',
      properties: {
        observations: {
          title: 'What is in the nest box?',
          $ref: '#/definitions/observation',
        },
        comment: {
          type: 'string',
          title: 'Notes',
        },
      },
    },
    {
      type: 'object',
      properties: {
        photos: {
          type: 'string',
          title: 'Photos',
        },
      },
    },
  ],
})

const uiSchema = {
  dateTime: {
    'ui:widget': 'DateTimeWidget',
    'ui:options': {
      placeholder: 'Select date and time',
    },
  },
  comment: {
    'ui:options': {
      rows: 4,
      resize: false,
      placeholder: 'Any notes about the observation?',
    },
    'ui:widget': 'textarea',
  },
  eggs: {
    'ui:widget': 'NumberWidget',
  },
  fledglings: {
    'ui:widget': 'NumberWidget',
  },
  nestlings: {
    'ui:widget': 'NumberWidget',
  },
  unhatchedEggs: {
    'ui:widget': 'NumberWidget',
  },
  deadNestlings: {
    'ui:widget': 'NumberWidget',
  },
  nestbox: {
    'ui:widget': 'SelectWidget',
    'ui:options': {
      placeholder: 'Select nestbox',
    },
  },
  species: {
    'ui:widget': 'SpeciesWidget',
    'ui:options': {
      placeholder: 'Select or type species',
    },
  },
  photos: {
    'ui:widget': 'PhotoWidget',
  },
  bandResight: {
    'ui:widget': 'BandResightWidget',
  }
}

export default {
  createSchema,
  uiSchema,
}
