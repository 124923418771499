import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  useIonAlert,
} from '@ionic/react'
import { useHistory, useParams } from 'react-router-dom'
import { withStyles, Typography } from '@material-ui/core'
import EditIcon from '@material-ui/icons/EditOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import DisplayObservationLocation from '../../components/maps/DisplayObservationLocation'
import Loading from '../../components/micros/Loading'
import actions from '../../actions'
import ObservationDetails from '../../components/observations/ObservationDetails'

const styles = (theme) => ({
  actions: {
    alignItems: 'center',
    display: 'flex',
    justifyItems: 'center',
    margin: '1rem 0',

    '& svg': {
      marginRight: '0.6rem',
    },

    '& > *:first-of-type': {
      marginRight: '1rem',
    },
  },
  modalPageHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
  },
  speciesName: {
    color: theme.palette.primary.main,
    fontSize: '2.4rem',
  },
  speciesSciName: {
    fontSize: '1.6rem',
    fontWeight: '400',
  },
})

const ViewObservation = (props) => {
  const {
    user,
    clearCurrentObservation,
    getObservation,
    deleteObservation,
    updatePersistTableDataOnStart,
    observation,
    classes,
  } = props
  const [error, setError] = useState(null)
  const [presentAlert] = useIonAlert()
  const params = useParams()
  const history = useHistory()

  useEffect(() => {
    updatePersistTableDataOnStart(false) // we set to true on navigation away to edit page, remove on return to this page
    getObservation(params.id)
      .then()
      .catch((e) => setError(e.errors[0]))

    return () => {
      clearCurrentObservation()
    }
  }, [params.id])

  const handleDeleteObservation = () => {
    return deleteObservation(observation._id).then(() =>
      history.push('/dashboard/me')
    )
  }

  const checkChangeObservationStatus = () => {
    // Project game camera only, if you're the owner and verified less than 7 days ago
    if (
      observation.creator?._id === user._id &&
      observation.project?.key === 'gameCameras' &&
      DateTime.fromISO(observation.verified).diffNow('day').days > -7
    ) {
      return true
    }

    if (
      ['N', 'Q'].includes(observation.status.code) &&
      (observation.creator?._id === user._id || user.level > 4)
    ) {
      return true
    }

    return false
  }

  if (error) {
    return (
      <IonPage>
        <IonHeader className={classes.modalPageHeader}>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
            <Typography component="h1" variant="h4">
              Observation Details
            </Typography>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <Typography>{error.message}</Typography>
        </IonContent>
      </IonPage>
    )
  }

  if (!observation) {
    return (
      <IonPage>
        <IonHeader className={classes.modalPageHeader}>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
            <Typography component="h2" variant="h4">
              Observation Details
            </Typography>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <Loading />
        </IonContent>
      </IonPage>
    )
  }

  const location = observation.nestbox || {
    utmEasting: observation.utmEasting,
    utmNorthing: observation.utmNorthing,
    utmZone: observation.utmZone,
    latitude: observation.latitude,
    longitude: observation.longitude,
  }

  const noSpeciesProject = ['beaver'].includes(observation.project.key)

  return (
    <IonPage>
      <IonHeader className={classes.modalPageHeader}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <Typography component="h1" variant="h4">
            Observation Details
          </Typography>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div>
          <div>
            {noSpeciesProject ? (
              <Typography component="h2" variant="h3">
                Beaver Project
              </Typography>
            ) : (
              <>
                <Typography
                  className={classes.speciesName}
                  component="h2"
                  variant="h5"
                >
                  {`${
                    observation.species &&
                    (observation.species.commonName || observation.species.name)
                  }`}
                </Typography>
                <Typography
                  className={classes.speciesSciName}
                  component="p"
                  variant="h6"
                >
                  {`${
                    (observation.species &&
                      observation.species.scientificName) ||
                    ''
                  }`}
                </Typography>
              </>
            )}
          </div>
          <div className={classes.actions}>
            {checkChangeObservationStatus() && (
              <>
                <IonButton
                  href={`/observation/edit/${observation.project.key}/${observation.entry}/0`}
                  onClick={() => {
                    updatePersistTableDataOnStart(true)
                  }}
                  title="Edit"
                >
                  <EditIcon />
                  Edit
                </IonButton>
                <IonButton
                  onClick={() =>
                    presentAlert({
                      header: 'Are you sure?',
                      message:
                        'You want to delete this observation. This action cannot be undone.',
                      buttons: [
                        {
                          text: 'Cancel',
                          role: 'cancel',
                          handler: () => {},
                        },
                        {
                          text: 'Yes',
                          role: 'confirm',
                          handler: () => {
                            handleDeleteObservation()
                          },
                        },
                      ],
                    })
                  }
                >
                  <DeleteIcon />
                  Delete
                </IonButton>
              </>
            )}
          </div>
        </div>
        <div className={classes.data}>
          <ObservationDetails observation={observation} />
          {user.level > 4 || user._id === observation.creator._id ? (
            <DisplayObservationLocation location={location} />
          ) : null}
        </div>
      </IonContent>
    </IonPage>
  )
}

const mapStateToProps = ({ form, observations, user }) => {
  return {
    observation: observations.current,
    user,
  }
}

const mapDispatchToProps = {
  clearCurrentObservation: actions.clearCurrentObservation,
  deleteObservation: actions.deleteObservation,
  getObservation: actions.getObservation,
  updatePersistTableDataOnStart: actions.updatePersistTableDataOnStart,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ViewObservation))
